import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Slider from 'react-slick'
import FullHeight from 'react-div-100vh'
import tw from 'tailwind.macro'
import posed from 'react-pose'
import poseProps from '../pose-props'

import logoGroup from '../images/logo-group-2x.png'

class MobileSplash extends Component {
    componentDidMount() {
        this.timer = setInterval(
            () =>
                this.props.dispatch({
                    type: 'UPDATE',
                    seenSplash: true,
                }),
            3000
        )
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    renderSlide = () => {
        return (
            <Slide>
                <Logos src={logoGroup} />
            </Slide>
        )
    }

    toggleSplash = () => {
        this.props.dispatch({
            type: 'UPDATE',
            seenSplash: true,
        })
    }

    render() {
        const settings = {
            autoplay: true,
            autoplaySpeed: 0,
            centerMode: true,
            infinite: true,
            vertical: true,
            speed: 4000,
            cssEase: 'linear',
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 1,
            arrows: false,
            buttons: false,
            swipe: false,
            touchmove: false,
            draggable: false,
            focusOnSelect: false,
            pauseOnHover: false,
            rtl: false,
        }

        const { seenSplash } = this.props
        // console.log(seenSplash)

        return (
            <PoseWrapper
                pose={seenSplash ? 'hidden' : 'visible'}
            >
                <Wrapper
                    id={'mobile-splash'}
                    onClick={this.toggleSplash}
                >
                    <Slider
                        {...settings}
                    >
                        {this.renderSlide()}
                        {this.renderSlide()}
                        {this.renderSlide()}
                        {this.renderSlide()}
                    </Slider>
                </Wrapper>
            </PoseWrapper>
        )
    }
}

const PoseWrapper = styled(posed.div(poseProps.mobileSplash))`
    background: white;
`

const Wrapper = styled(FullHeight)`
    ${tw`fixed inset-0 w-full h-full overflow-hidden bg-white hidden sm:block`}
`

const Slide = styled.div`
    ${tw`outline-none w-full h-full`}
    margin-bottom: -120px;
`

const Logos = styled.img`
    ${tw`w-full h-auto`}
`

MobileSplash.propTypes = {
    seenSplash: PropTypes.bool,
}

const mapStateToProps = state => ({
    seenSplash: state.splash.seenSplash,
})

export default connect(mapStateToProps)(MobileSplash)
